export const API_URL = 'https://api.altidrh.com/api/';
//export const API_URL = 'http://www.altid-rh-api.com.mx:8084/api/';
export const APP_URL = 'https://altidrh.com';
export const APP_PREFIX = 'altidrh';

export const USER_ROLES = [
    {role: 'administrador', label: 'Administrador'},
    {role: 'operaciones', label: 'Operaciones'},
    {role: 'coordinador', label: 'Coordinador'},
    {role: 'supervisor', label: 'Supervisor'},
    {role: 'cliente', label: 'Cliente'},
    {role: 'solo vista', label: 'Solo Vista'}
];

export const ESTADOS_CIVILES = [
    {key: 'Soltero', value: 'Soltero'},
    {key: 'Casado', value: 'Casado'},
    {key: 'Divorciado', value: 'Divorciado'},
    {key: 'Viudo', value: 'Viudo'},
    {key: 'Otro', value: 'Otro'},
    {key: 'Pendiente', value: 'Pendiente'}
];
